* {
    box-sizing: border-box;
    scrollbar-width: none
}
::-webkit-scrollbar {
    display: none;
}
@import url('https://fonts.googleapis.com/css?family=Baloo+Bhai|Montserrat|Special+Elite&display=swap');
body {
    margin: 0 0 0 0;
    font-family:'Montserrat', Sans-Serif;
}
ul,li{
    margin:0;
    padding:0;
    list-style: none;
}
a{
    text-decoration: none;
    color:inherit;
}
/*
@font-face {
    font-family: 'Lance Sans',sans-serif;
    font-style: normal;
    font-weight: normal;
    src: url('/fonts/LanceSansRg.otf');
}
@font-face {
    font-family: 'Bobby Jones',Sans-Serif;;
    font-style: normal;
    font-weight: normal;
    src: url('/fonts/BobbyJonesSoft.otf');
} */

@font-face {
    font-family: 'Montserrat', Sans-Serif;
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Next LT Pro Regular'), url('/fonts/AvenirNextLTPro-Regular.woff') format('woff');
    }
@font-face {
    font-family: 'Avenir Next Bold', Sans-Serif;
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Next LT Pro Bold'), url('/fonts/AvenirNextLTPro-Bold.woff') format('woff');
}